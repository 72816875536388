.imgg {
    max-width: 100%;
    display: block;
}

ul {
    list-style: none;
}


/* Utilities */

.c::after,
.c img {
    border-radius: 50%;
}


/* body,
.c,
.stats {
    display: flex;
} */

.c {
    padding: 2.5rem 2rem;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.5);
    max-width: 500px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
    margin: 1rem;
    width: 450px;
    height: 370px;
    position: relative;
    transform-style: preserve-3d;
    overflow: hidden;
}

.c::before,
.c::after {
    content: "";
    position: absolute;
    z-index: -1;
}

.c::before {
    width: 100%;
    height: 100%;
    border: 1px solid #fff;
    border-radius: 10px;
    top: -0.7rem;
    left: -0.7rem;
}

.c::after {
    height: 15rem;
    width: 15rem;
    background-color: #4172f5aa;
    top: -8rem;
    right: -8rem;
    box-shadow: 2rem 6rem 0 -3rem #fff;
}

.c img {
    width: 1rem;
    min-width: 80px;
    box-shadow: 0 0 0 5px #fff;
}

.infos {
    margin-left: 1.5rem;
}

.name {
    margin-bottom: 1rem;
    font-size: 25px;
    color: #394eea;
}

.name p {
    font-size: 12px;
}

.name h4 {
    font-size: 0.8rem;
    color: #4172f5aa;
}

.text {
    font-size: 0.9rem;
    margin-bottom: 1rem;
}

.stats {
    margin-bottom: 1rem;
}

.stats li {
    min-width: 5rem;
}

.stats li h2 {
    font-size: 20px;
}

.stats li h4 {
    font-size: 17px;
    color: #2d3d5f;
}

@media screen and (max-width: 450px) {
    .c {
        display: block;
    }
    .infos {
        margin-left: 0;
        margin-top: 1.5rem;
    }
    .links button {
        min-width: 100px;
    }
}