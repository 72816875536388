.css-zdqc84 {
    background-color: rgb(255, 255, 255);
    color: rgb(17, 24, 39);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 16px;
    max-width: 576px;
    width: 100%;
    text-align: center;
    overflow: hidden;
    box-shadow: rgb(0 0 0 / 10%) 0px 10px 15px -3px, rgb(0 0 0 / 5%) 0px 4px 6px -2px;
}

.css-1oqm2v0 {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: Poppins, sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    min-width: 64px;
    padding: 6px 16px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: rgb(255, 255, 255);
    background-color: rgb(10, 143, 220);
    box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
    border-radius: 8px;
    text-transform: capitalize;
    width: 100%;
    height: 44px;
}

.css-1mafxii {
    margin-bottom: 6px;
    font-weight: 700;
    font-size: 20px;
}

.css-1oqm2v0:disabled {
    background-color: rgb(124, 199, 243);
}