.sidebar-container {
  position: fixed;
  width: 220px;
  height: 103%;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #2d3b5f;
  color: whitesmoke;
  margin-top: -20px;
}

.sidebarlogo {
  padding: 10px 10px 10px 10px;
  font-size: 11px;
}

.sidebar-navigation {
  padding: 0;
  margin: 0;
  list-style-type: none;
  position: relative;
}

.sidebar-navigation li {
  background-color: transparent;
  position: relative;
  display: inline-block;
  width: 100%;
  line-height: 20px;
}

.sidebar-navigation li a {
  padding: 10px 15px 10px 30px;
  display: block;
  color: #fff;
  font-size: 18px;
  font-weight: 300;
  text-decoration: none;
}

.sidebar-navigation li .fa {
  margin-right: 10px;
}

.sidebar-navigation li a:active,
.sidebar-navigation li a:hover,
.sidebar-navigation li a:focus {
  text-decoration: none;
  outline: none;
  font-weight: 700;
  color: #ffffff;
}

.sidebar-navigation li::before {
  background-color: #2574a9;
  position: absolute;
  content: '';
  height: 100%;
  left: 0;
  top: 0;
  -webkit-transition: width 0.2s ease-in;
  transition: width 0.2s ease-in;
  width: 3px;
  z-index: -1;
}

.sidebar-navigation li:hover::before {
  width: 100%;
}

.sidebar-navigation .header {
  font-size: 12px;
  text-transform: uppercase;
  background-color: #151515;
  padding: 10px 15px 10px 30px;
}

.sidebar-navigation .header::before {
  background-color: transparent;
}

.content-container {
  padding-left: 220px;
}

.f {
  font-family: 'Avenir LT Std', sans-serif;
}

*/
/* new css */

a {
  text-decoration: none;
  -webkit-transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  transition: all 0.6s ease;
}

a:hover {
  -webkit-transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  transition: all 0.6s ease;
}

/* .appp {
    height: 100vh;
} */

/* -------------
Sidebar
----------------*/

.sidebar {
  position: fixed;
  width: 219px;
  height: 100%;
  top: 0;
  overflow: hidden;
  background-color: rgb(255, 255, 255);
  /* box-shadow: 0 0 30px rgba(0, 0, 0, 0.15); */
  transform: translateZ(0);
  visibility: visible;
  backface-visibility: hidden;
}

.sidebar header {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background-color: rgb(255, 255, 255);
  width: 100%;
  display: block;
  padding: 0.75em 1em;
}

/* -------------
Sidebar Nav
----------------*/

.sidebar-nav {
  position: fixed;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  background-color: rgb(255, 255, 255);
  height: 100%;
  font-weight: 400;
  font-size: 1.2em;
  overflow: auto;
  padding-bottom: 6em;
  z-index: 9;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  /* -------------
  Chev elements
  ----------------*/
  /* -------------
  Nav-Flyout
  ----------------*/
  /* -------------
  Hover
  ----------------*/
}

.sidebar-nav ul {
  list-style: none;
  display: block;
  padding: 0;
  margin: 0;
}

.sidebar-nav ul li {
  margin-left: 0;
  padding-left: 0;
  display: inline-block;
  width: 100%;
  font-weight: 600;
  /* -------------
  Sidebar: icons
  ----------------*/
}

.sidebar-nav ul li a {
  color: #000000b3;
  font-size: 0.75em;
  padding: 1.05em 1em;
  position: relative;
  display: block;
}

.sidebar-nav ul li a:hover {
  background-color: rgb(218, 218, 218);
  -webkit-transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  transition: all 0.6s ease;
}

.sidebar-nav ul li a:active {
  background-color: rgb(244, 249, 254);
}

.sidebar-nav ul li i {
  font-size: 1.8em;
  padding-right: 0.5em;
  width: 9em;
  display: inline;
  vertical-align: middle;
}

.sidebar-nav > ul > li > a:after {
  content: ' ';
  font-family: ionicons;
  font-size: 0.5em;
  width: 10px;
  color: #fff;
  position: absolute;
  right: 0.75em;
  top: 45%;
}

.sidebar-nav .nav-flyout {
  position: absolute;
  background-color: rgb(218, 218, 218);
  z-index: 9;
  color: white !important;
  left: 2.5em;
  top: 0;
  height: 100vh;
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.sidebar-nav .nav-flyout a:hover {
  background-color: rgb(244, 249, 254);
}

.sidebar-nav ul > li:hover .nav-flyout {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.active-tab {
  background-color: #0d6efd !important; /* Bootstrap primary blue */
  color: white !important;
  font-weight: bold;
  border-left: 4px solid #ffffff; /* Highlight active tab */
}
