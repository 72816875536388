body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  /* font-family: "Roboto", sans-serif; */
}

.invalid input {
  border: 1px solid #b40e0e;
  background-color: #fddddd;
}

/* CSS */

.custom-button {
  background-color: #2574a9;
}

/* CSS */

.button-3 {
  background-color: #6777ef;
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: 'Haas Grot Text R Web', 'Helvetica Neue', Helvetica, Arial,
    sans-serif;
  font-size: 16px;
  font-weight: 500;
  height: 40px;
  line-height: 20px;
  list-style: none;
  margin: 0;
  outline: none;
  padding: 10px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: color 100ms;
  vertical-align: baseline;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-3:hover,
.button-3:focus {
  background-color: #394eea;
}

.button-3:disabled {
  background-color: #707fa5;
}

.btn-danger,
.btn-danger.disabled {
  /* box-shadow: 0 2px 6px #fd9b96; */
  background-color: #fc544b;
  border-color: #fc544b;
  color: #fff;
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.disabled:hover,
.btn-danger.disabled:focus,
.btn-danger.disabled:active {
  background-color: #fb160a !important;
}

.btn-outline-danger:hover,
.btn-outline-danger:focus,
.btn-outline-danger:active,
.btn-outline-danger.disabled:hover,
.btn-outline-danger.disabled:focus,
.btn-outline-danger.disabled:active {
  background-color: #fb160a !important;

  color: #fff !important;
}

.btn-success,
.btn-success.disabled {
  /* box-shadow: 0 2px 6px #81d694; */
  background-color: #47c363;

  border-color: #47c363;
  color: #fff;
}

.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.disabled:hover,
.btn-success.disabled:focus,
.btn-success.disabled:active {
  background-color: #3bb557 !important;
  color: #fff !important;
}

.btn-outline-success:hover,
.btn-outline-success:focus,
.btn-outline-success:active,
.btn-outline-success.disabled:hover,
.btn-outline-success.disabled:focus,
.btn-outline-success.disabled:active {
  background-color: #47c363 !important;
  color: #fff !important;
}

.badge {
  vertical-align: middle;
  padding: 7px 12px;
  font-weight: 600;
  letter-spacing: 0.3px;
  border-radius: 30px;
  font-size: 12px;
}

.badge.badge-warning {
  color: #fff;
}

.badge.badge-primary {
  background-color: #6777ef;
}

.badge.badge-secondary {
  background-color: #cdd3d8;
}

.badge.badge-success {
  background-color: #47c363;
}

.badge.badge-info {
  background-color: #3abaf4;
}

.badge.badge-danger {
  background-color: #fc544b;
}

.badge.badge-light {
  background-color: #e3eaef;
  color: #191d21;
}

.badge.badge-white {
  background-color: #ffffff;
  color: #191d21;
}

.badge.badge-dark {
  background-color: #191d21;
}
