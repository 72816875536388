body {
  margin-top: 20px;
  background: rgb(94, 149, 204);
}

.order-card {
  color: #fff;
}

.bg-c-blue {
  background: linear-gradient(45deg, #4099ff, #73b4ff);
}

.card-c-blue {
  color: linear-gradient(45deg, #4099ff, #73b4ff);
}

.bg-c-green {
  background: linear-gradient(45deg, #2ed8b6, #59e0c5);
}

.bg-c-yellow {
  background: linear-gradient(45deg, #ffb64d, #ffcb80);
}

.bg-c-pink {
  background: linear-gradient(45deg, #ff5370, #ff869a);
}

.card-color-pink {
  color: linear-gradient(45deg, #ff5370, #ff869a);
  /* color: linear-gradient(45deg, #ff5370, #ff869a); */
}

.bg-c-white {
  background: white;
}

.card {
  border-radius: 16px;
  /* -webkit-box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
  box-shadow: rgb(0 0 0 / 4%) 0px 10px 10px 4px; */
  border: none;
  /* margin-bottom: 10px; */
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.card .card-block {
  padding: 25px;
}

.order-card i {
  font-size: 26px;
}

.f-left {
  float: left;
}

.f-right {
  float: right;
}

.btn-c-green {
  background: linear-gradient(45deg, #0dd4ad, #20a086);
}

.btn-c-yellow {
  background: linear-gradient(45deg, #dd9734, #cf984b);
}

.btn-c-pink {
  background: linear-gradient(45deg, #e04660, #d66678);
}
