/* .show {
    /* margin-top: -35px;
    margin-bottom: 20px; */

.mar {
    margin-right: -12px;
    margin-left: -12px;
    margin-top: -20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.b-radius {
    border-radius: 10%;
}

.heading-color {
    color: #2d3d5f;
}

.bc {
    background-color: rgb(255, 255, 255);
}