.btn-primary,
.btn-primary.disabled {
  /* box-shadow: 0 2px 6px #acb5f6; */
  background-color: #6777ef;
  border-color: #6777ef;
}

.btn-primary:focus,
.btn-primary.disabled:focus {
  background-color: #394eea !important;
}

.btn-primary:focus:active,
.btn-primary.disabled:focus:active {
  background-color: #394eea !important;
}

.btn-primary:active,
.btn-primary:hover,
.btn-primary.disabled:active,
.btn-primary.disabled:hover {
  background-color: #394eea !important;
}

.btn-outline-primary,
.btn-outline-primary.disabled {
  border-color: #6777ef;
  color: #6777ef;
}

.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:active,
.btn-outline-primary.disabled:hover,
.btn-outline-primary.disabled:focus,
.btn-outline-primary.disabled:active {
  background-color: #6777ef !important;
  color: #fff;
}

/* .sh {
    box-shadow: rgb(0 0 0 / 4%) 0px 10px 10px 4px;
    border-radius: 16px;
} */
